














import mixins from 'vue-typed-mixins';
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import { SmartTableTable, Config, Column, Sorting, State } from 'rey-vue-smarttable';

export default mixins(SettingsMixin, SharedDataMixin, FileDownloadMixin).extend({
  components: { SmartTableTable },
  name: 'departments',
  props: {
    itemId: String
  },
  data: function () {
    return {
      smartTableConfig: new Config(
        (row) => row.code,
        [
          new Column({
            title: 'Code',
            fieldName: 'code',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Name',
            fieldName: 'name',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Abteilung (Abacus)',
            fieldName: 'assignedAccountingDepartmentCode',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Konto (Abacus)',
            fieldName: 'assignedAccountingAccountNumber',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Beschreibung Abteilung (Abacus)',
            fieldName: 'accountingDescription',
            fieldType: 'string',
            editable: () => false
          })
        ]
      ),
      smartTableState: new State([new Sorting('code')])
    };
  }
});
