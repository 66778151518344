import IOrderExpanded from './IOrderExpanded';
import OrderStatus from './OrderStatus';
import OrderLockStatus from './OrderLockStatus';
import ReportStatus from './ReportStatus';
import Language from './Language';
import ReportType from './ReportType';
import OrderType from './OrderType';
import ContractType from './ContractType';
import InvoiceMode from './InvoiceMode';
import ICustomer from './ICustomer';
import CrmContact from './CrmContact';
import { AxiosInstance } from 'axios';
import IOrderFlatRateDefinition from './AutomaticInvoicing/IOrderFlatRateDefinition';

export default class Order implements IOrderExpanded {
  public static FromInterface(order: IOrderExpanded): Order {
    return Object.assign(new Order(order.departmentCode), order);
  }

  public id: string | undefined;
  public status: OrderStatus;
  public lockStatus: OrderLockStatus;
  public reportStatus: ReportStatus;
  public receiptDate: Date;
  public deliveryDate: Date;
  public invoiceDate: Date | undefined;
  public invoiceComment: string | undefined;
  public sampleTaker: string | undefined;
  public departmentCode: string;
  public language: Language;
  public reportType: ReportType;
  public orderType: OrderType;
  public orderDescription: string | undefined;
  public internalNotes: string | undefined;
  public reportComment: string | undefined;
  public revisionComment: string | undefined;
  public customerInfo: string | undefined;
  public customerConditions: string | undefined;
  public contractType: ContractType;
  public invoiceMode: InvoiceMode;
  public customerNumber: number | undefined;
  public customerFibuNumber: number | undefined;
  public customerCompanyName: string | undefined;
  public customerAdditionalInfo: string | undefined;
  public customerDepartment: string | undefined;
  public customerSalutation: string | undefined;
  public customerContact: string | undefined;
  public customerContactFirstName: string | undefined;
  public customerContactLastName: string | undefined;
  public customerContactEmail: string | undefined;
  public customerEmail: string | undefined;
  public customerAddress: string | undefined;
  public customerAddressLine2: string | undefined;
  public customerPostOfficeBox: string | undefined;
  public customerZipCode: string | undefined;
  public customerCity: string | undefined;
  public customerCountry: string | undefined;
  public customerIsPurchaseOrderNumberRequired: boolean | undefined;
  public customerIsCustomerLocked: boolean | undefined;
  public invoiceAddressNumber: number | undefined;
  public invoiceAddressFibuNumber: number | undefined;
  public invoiceAddressCompanyName: string | undefined;
  public invoiceAddressEmail: string | undefined;
  public invoiceAddressTin: string | undefined;
  public invoiceAddressAdditionalInfo: string | undefined;
  public invoiceAddressDepartment: string | undefined;
  public invoiceAddressSalutation: string | undefined;
  public invoiceAddressAddress: string | undefined;
  public invoiceAddressAddressLine2: string | undefined;
  public invoiceAddressPostOfficeBox: string | undefined;
  public invoiceAddressZipCode: string | undefined;
  public invoiceAddressCity: string | undefined;
  public invoiceAddressCountry: string | undefined;
  public discountPercent: number | undefined;
  public extraCharge1Text: string | undefined;
  public extraCharge1DepartmentCode: string;
  public extraCharge1InChf = 0;
  public extraCharge2Text: string | undefined;
  public extraCharge2DepartmentCode: string;
  public extraCharge2InChf = 0;
  public extraCharge3Text: string | undefined;
  public extraCharge3DepartmentCode: string;
  public extraCharge3InChf = 0;
  public orderFlatRateText: string | undefined;
  public orderFlatRateDepartmentCode: string;
  public orderFlatRateInChf = 0;
  public invoiceTextLine4: string | undefined;
  public invoiceTextLine5: string | undefined;
  public samplesInHouseTotalChf = 0;
  public samplesSubcontractedTotalChf = 0;
  public samplesInHouseDiscountChf = 0;
  public invoiceTextLine1 = '';
  public invoiceTextLine2 = '';
  public invoiceTextLine3 = '';
  public currentSampleNumber: number | undefined;
  public bppId: string | undefined;
  public createdBy: string | undefined;
  public createdAt: Date | undefined;
  public canOrderBeCopied: boolean | undefined;
  public purchaseOrderNumber: string | undefined;

  constructor(departmentCode: string) {
    this.status = OrderStatus.New;
    this.lockStatus = OrderLockStatus.Unlocked;
    this.reportStatus = ReportStatus.New;
    this.departmentCode = departmentCode;
    this.receiptDate = new Date();

    const deliveryDate = new Date();
    deliveryDate.setDate(new Date().getDate() + 7);
    this.deliveryDate = deliveryDate;

    this.language = Language.DE;
    this.reportType = ReportType.Standard;
    this.orderType = OrderType.Standard;
    this.contractType = ContractType.Standard;
    this.invoiceMode = InvoiceMode.Standard;
    this.extraCharge1DepartmentCode = departmentCode;
    this.extraCharge2DepartmentCode = departmentCode;
    this.extraCharge3DepartmentCode = departmentCode;
    this.orderFlatRateDepartmentCode = 'DV-AP';
  }

  public setCustomerDetails(customer: ICustomer): void {
    this.customerNumber = +customer.kundennr;
    this.customerCompanyName = customer.firma;
    this.customerAdditionalInfo = customer.zusatz || undefined;
    this.customerDepartment = customer.abteilung_intern || undefined;
    this.customerSalutation = customer.anrede || undefined;
    this.customerAddress = customer.strasse || undefined;
    this.customerAddressLine2 = customer.adresszusatz || undefined;
    this.customerPostOfficeBox = customer.postfach || undefined;
    this.customerZipCode = customer.plz || undefined;
    this.customerCity = customer.ort || undefined;
    this.customerCountry = customer.land_code || undefined;
    this.customerInfo = customer.memo;
    this.customerConditions = customer.konditionen;
    this.customerEmail = customer.email || undefined;
    this.customerIsCustomerLocked = customer.gesperrt == '1';
    this.customerIsPurchaseOrderNumberRequired = customer.po_bestellnr_erforderlich == 'true';
  }

  public setCustomerContact(contact: CrmContact): void {
    if (contact) {
      this.customerContact = contact.fullName;
      this.customerContactFirstName = contact.vorname;
      this.customerContactLastName = contact.nachname;
      this.customerSalutation = contact.anrede || undefined;
      this.customerContactEmail = contact.email;
    } else {
      this.customerContact = undefined;
      this.customerContactFirstName = undefined;
      this.customerContactLastName = undefined;
    }
  }

  public setInvoiceAddress(invoiceAddress: ICustomer): void {
    this.invoiceAddressNumber = +invoiceAddress.kundennr;
    this.invoiceAddressFibuNumber = invoiceAddress.fibunr;
    this.invoiceAddressCompanyName = invoiceAddress.firma;
    this.invoiceAddressEmail = invoiceAddress.debitor?.email;
    this.invoiceAddressTin = invoiceAddress.debitor?.mwst_nr;
    this.invoiceAddressAdditionalInfo = invoiceAddress.zusatz || undefined;
    this.invoiceAddressDepartment = invoiceAddress.abteilung_intern || undefined;
    this.invoiceAddressSalutation = invoiceAddress.anrede || undefined;
    this.invoiceAddressAddress = invoiceAddress.strasse || undefined;
    this.invoiceAddressAddressLine2 = invoiceAddress.adresszusatz || undefined;
    this.invoiceAddressPostOfficeBox = invoiceAddress.postfach || undefined;
    this.invoiceAddressZipCode = invoiceAddress.plz || undefined;
    this.invoiceAddressCity = invoiceAddress.ort || undefined;
    this.invoiceAddressCountry = invoiceAddress.land_code || undefined;
  }

  public async applyOrderFlatRateIfNecessary(
    axiosInstance: AxiosInstance,
    customer: ICustomer
  ): Promise<void> {
    if (customer.auftragspauschale == 'true') {
      const hadOrderFlatRateSetBefore = this.orderFlatRateInChf > 0;
      if (this.orderFlatRateText == '' || this.orderFlatRateText == undefined) {
        this.orderFlatRateText = 'Auftragspauschale';
      }
      if (!hadOrderFlatRateSetBefore) {
        const response = await axiosInstance
          .get<IOrderFlatRateDefinition>(`/api/FibuParameter/EffectiveOrderFlatRate`)
          .then((response) => response)
          .catch(() => null);
        if (response == null || response.status != 200) {
          return;
        }

        this.orderFlatRateInChf = response.data.value;
      }
    } else {
      this.orderFlatRateInChf = 0;
    }
  }
}
