import IInvoiceSummary from './IInvoiceSummary';
import IInvoiceSummaryDepartment from './IInvoiceSummaryDepartment';

export default class InvoiceSummary implements IInvoiceSummary {
  public caption = '';
  public description = '';
  public sortOrder = 0;
  public amountsPerAccountingDepartment: IInvoiceSummaryDepartment[] = [];
  public getAmountsPerAccountingDepartment: { [key: string]: IInvoiceSummaryDepartment } = {};

  public static FromInterface(summary: IInvoiceSummary): InvoiceSummary {
    const invoiceSummary = Object.assign(new InvoiceSummary(), summary);
    for (const accountingDepartment of summary.amountsPerAccountingDepartment) {
      invoiceSummary.getAmountsPerAccountingDepartment[accountingDepartment.code] =
        accountingDepartment;
    }
    return invoiceSummary;
  }
}
