import { render, staticRenderFns } from "./OrderDetails.vue?vue&type=template&id=965a35e2&scoped=true&"
import script from "./OrderDetails.vue?vue&type=script&lang=ts&"
export * from "./OrderDetails.vue?vue&type=script&lang=ts&"
import style0 from "./OrderDetails.vue?vue&type=style&index=0&id=965a35e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_jmni46ydenuhv6sytlbyqtthlq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "965a35e2",
  null
  
)

export default component.exports