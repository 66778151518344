





















































































































































































import $ from 'jquery';
import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import { RemoteData } from 'rey-frontend-fp';
import IDepartment from '@/models/IDepartment'; // NOSONAR
import SettingsMixin from '@/mixins/SettingsMixin';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import UserMixin from '@/mixins/UserMixin';
import { msalMixin } from 'vue-msal';
import AuthenticationStateService from './services/AuthenticationStateService';

export default mixins(SettingsMixin, SharedDataMixin, UserMixin, msalMixin).extend({
  data: function () {
    return {
      departmentsRd: RemoteData.notAsked<Array<IDepartment>, Error | string>(),
      department: '',
      typeaheadOrderIdsRd: RemoteData.notAsked<Array<string>, Error | string>(),
      typeaheadInput: '',
      typeaheadOrderId: null
    };
  },
  created: async function () {
    this.cloneSessionStorage();
    // @ts-ignore
    this.department = this.$department;
    await this.loadDepartments();
    this.storeDepartments();
    this.detectBrowser();
  },
  computed: {
    isAdminUser: function (): boolean {
      return AuthenticationStateService.authenticatedUserHasAdminPermissions(
        // @ts-ignore
        this.msal.graph?.groups?.value
      );
    },
    typeaheadOrderIdsSorted: function (): string[] {
      return this.typeaheadOrderIdsRd
        .map((array) => {
          array.sort();
          array.reverse();
          return array;
        })
        .withDefault([]);
    },
    environment: function (): string {
      return process.env.VUE_APP_ENVIRONMENT || '';
    }
  },
  watch: {
    department: function (value: string) {
      // @ts-ignore
      this.$department = value;
    },
    typeaheadInput: _.debounce(function (value: string) {
      // @ts-ignore
      this.loadOrderIds(value);
    }, 500)
  },
  methods: {
    cloneSessionStorage: function () {
      if (!sessionStorage.length) {
        localStorage.setItem('cloneSessionStorage', '' + Date.now());
      }

      window.addEventListener('storage', function (event) {
        if (event.key == 'cloneSessionStorage') {
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
          localStorage.removeItem('sessionStorage');
        } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
          const data = JSON.parse(event.newValue || '[]');
          for (const key in data) {
            sessionStorage.setItem(key, data[key]);
          }
        }
      });
    },
    onHitTypeahead: function (value: string) {
      this.$router.push(`/orders/${value}`);
    },
    loadOrderIds: async function (value: string) {
      // @ts-ignore
      this.typeaheadOrderIdsRd = await this.$labordatApi.getRd<Array<string>, Error | string>(
        `/api/orders/ids?startsWith=${value}`
      );
    },
    loadDepartments: async function () {
      this.departmentsRd = await this.$labordatApi.getRd('/api/departments');
    },
    storeDepartments: function () {
      // @ts-ignore
      this.$departments = this.departmentsRd.withDefault(this.$departments);
    },
    detectBrowser: function () {
      const userAgent = navigator.userAgent;

      /* MSIE used to detect old browsers and Trident used to newer ones*/
      if (userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1) {
        // @ts-ignore
        $('#unsupportedBrowserModal').modal('show');
      }

      // @ts-ignore
      const isChrome = window.chrome != null && window.navigator.vendor === 'Google Inc.';
      if (!isChrome) {
        $('#browserWarning').addClass('display-fixed');
      }
    }
  }
});
