
















































































































import _ from 'lodash';
import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import OrderMixin from '@/mixins/OrderMixin';
import DateTimeMixin from '@/mixins/DateTimeMixin';
import SigneesMixin from '@/mixins/SigneesMixin';
import FileDownloadMixin from '@/mixins/FileDownloadMixin';
import UserMixin from '@/mixins/UserMixin';
import FormatMixin from '@/mixins/FormatMixin';
import IGenerateInvoice from '@/models/IGenerateInvoice';
import OrderType from '@/models/OrderType';
import { SmartTableTable, State, Sorting, Config, Column } from 'rey-vue-smarttable';
import InvoiceSummary from '@/models/InvoiceSummary';
import IInvoiceSummary from '@/models/IInvoiceSummary';
import IInvoiceSummaryDepartment from '@/models/IInvoiceSummaryDepartment';
import SmartTableConfigManagementMixin from '@/mixins/SmartTableConfigManagementMixin';

export default mixins(
  SharedDataMixin,
  OrderMixin,
  DateTimeMixin,
  SigneesMixin,
  FileDownloadMixin,
  UserMixin,
  FormatMixin,
  SmartTableConfigManagementMixin
).extend({
  components: { SmartTableTable },
  data: function () {
    return {
      tableId: 'InvoicingSummary',
      invoicingState: 'None' as 'None' | 'Generating' | 'Success' | 'Error',
      hasApiError: false,
      allReportsGenerated: false,
      updateDebounced: _.debounce(
        function () {
          // @ts-ignore
          this.updateComment();
        }.bind(this),
        1000
      ),
      smartTableConfig: undefined as Config | undefined,
      smartTableState: new State([new Sorting('sortOrder')]),
      invoicingSummary: [] as InvoiceSummary[],
      purchaseOrderInputValue: '',
      needToDisplayIsRequiredHintOnPurchaseOrderInput: false
    };
  },
  created: async function () {
    this.initializeSmartTable();
  },
  mounted: async function () {
    this.purchaseOrderInputValue = this.orderRd.getData().purchaseOrderNumber ?? '';
    this.allReportsGenerated = await this.$labordatApi
      .post(`/api/orders/${this.orderRd.getData().id}/reports/areallreportsgenerated`)
      .then((response) => response.data as boolean);
    this.invoicingSummary = await this.$labordatApi
      .get(`/api/orders/${this.orderRd.getData().id}/invoicing/summary`)
      .then(
        (response) =>
          response.data.map((s: IInvoiceSummary) =>
            InvoiceSummary.FromInterface(s)
          ) as InvoiceSummary[]
      );

    this.loadSmartTableConfig(this.tableId).then((config) => {
      if (config) {
        // @ts-ignore
        this.$refs.invoicingSmartTable.setLayout(config);
      }
    });
  },
  computed: {
    isAllowedToInvoice: function (): boolean {
      return (
        !this.orderIsLocked &&
        !this.orderRd.getData().customerIsCustomerLocked &&
        (this.allReportsGenerated === true ||
          this.orderRd.map((order) => order.orderType === OrderType.NoSample).withDefault(false))
      );
    }
  },
  methods: {
    initializeSmartTable: function () {
      this.smartTableConfig = new Config(
        (row: InvoiceSummary) => `${row.sortOrder}`,
        [
          new Column({
            title: 'Titel',
            fieldName: 'caption',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Beschreibung',
            fieldName: 'description',
            fieldType: 'string',
            editable: () => false
          })
        ]
      );

      for (const availableAccountingDepartment of [
        ...new Set(this.$departments.map((x) => x.assignedAccountingDepartmentCode))
      ]) {
        this.smartTableConfig.columns?.push(
          new Column({
            title: availableAccountingDepartment,
            fieldName: `Department${availableAccountingDepartment}`,
            fieldType: 'numeric',
            editable: () => false,
            converter: (_cellValue, row) => {
              const summary = row.getAmountsPerAccountingDepartment[
                availableAccountingDepartment
              ] as IInvoiceSummaryDepartment;
              return this.withSeparators(
                this.formatWithPrecision(
                  summary && summary.amount != 0 ? '' + summary.amount : '',
                  2
                )
              );
            },
            colStyling: 'text-right'
          })
        );
      }
    },
    async updateSmartTableConfig() {
      this.saveSmartTableConfigDebounced(
        this.tableId,
        // @ts-ignore
        this.$refs.invoicingSmartTable.getLayout()
      );
    },
    createInvoice: async function () {
      const isPurchaseOrderInputValueProvided =
        !!this.purchaseOrderInputValue && this.purchaseOrderInputValue != '';
      if (
        this.orderRd.getData().customerIsPurchaseOrderNumberRequired &&
        !isPurchaseOrderInputValueProvided
      ) {
        this.needToDisplayIsRequiredHintOnPurchaseOrderInput = true;
        return;
      }
      try {
        this.invoicingState = 'Generating';
        await this.$labordatApi
          .post(`/api/orders/${this.orderRd.getData().id}/invoicing`, {
            sampleIds: [0],
            purchaseOrderNumber: this.purchaseOrderInputValue,
            comment: this.orderRd.getData().invoiceComment
          } as IGenerateInvoice)
          .then(async (response) => {
            this.invoicingState = response.status === 200 ? 'Success' : 'Error';
            await this.loadOrder(this.orderRd.getData().id || '');
            if (this.$refs.invoiceComment) {
              const textArea = this.$refs.invoiceComment as HTMLTextAreaElement;
              textArea.disabled = true;
            }
          });
      } catch (error) {
        this.invoicingState = 'Error';
      }
    },
    updateComment: async function () {
      try {
        await this.$labordatApi.post(`/api/orders/${this.orderRd.getData().id}/invoicing/comment`, {
          sampleIds: [0],
          comment: this.orderRd.getData().invoiceComment
        } as IGenerateInvoice);
      } catch (error) {
        this.hasApiError = true;
      }
    }
  }
});
