import mixins from 'vue-typed-mixins';
import CommonMixin from './CommonMixin';
import { UpdatableRemoteData } from 'rey-frontend-fp';
import Order from '@/models/Order';
import OrderStatus from '@/models/OrderStatus';
import OrderLockStatus from '@/models/OrderLockStatus';
import IOrderExpanded from '@/models/IOrderExpanded';

export default mixins(CommonMixin).extend({
  props: {
    orderRemotedata: Object
  },
  data: function () {
    return {
      orderRd: this.orderRemotedata as UpdatableRemoteData<Order, Error>
    };
  },
  computed: {
    orderIsCompleted: function (): boolean {
      return this.orderRd.map((order) => order.status >= OrderStatus.Completed).withDefault(false);
    },
    orderIsLocked: function (): boolean {
      return this.orderRd
        .map((order) => order.lockStatus !== OrderLockStatus.Unlocked)
        .withDefault(false);
    }
  },
  watch: {
    orderRd: {
      deep: true,
      handler: function (value) {
        this.$emit('update:order-remotedata', value);
      }
    },
    orderRemotedata(value) {
      this.orderRd = value;
    }
  },
  methods: {
    updateOrder: async function () {
      try {
        this.orderRd = UpdatableRemoteData.updating(this.orderRd.getData());
        const order: Order = await this.$labordatApi
          .put(`/api/orders/${this.orderRd.getData().id}`, this.orderRd.getData())
          .then((response) => response.data as IOrderExpanded)
          .then((data) => Order.FromInterface(data));
        this.orderRd = UpdatableRemoteData.success(order);
      } catch (error) {
        this.orderRd = UpdatableRemoteData.updateFailure(this.orderRd.getData(), error as Error);
      }
    },
    loadOrder: async function (orderId: string) {
      this.orderRd = await this.$labordatApi
        .get(`/api/orders/${orderId}`)
        .then((response) => response.data as IOrderExpanded)
        .then((data) => UpdatableRemoteData.success<Order, Error>(Order.FromInterface(data)))
        .catch((error) => UpdatableRemoteData.failure<Order, Error>(error));
    }
  }
});
