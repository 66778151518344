






























import mixins from 'vue-typed-mixins';
import SharedDataMixin from '@/mixins/SharedDataMixin';
import ValidatorMixin from '@/mixins/ValidatorMixin';
import { Sorting, Config, Column, hasValue } from 'rey-vue-smarttable';
import MasterDataGeneric from '@/views/MasterData/MasterDataGeneric.vue';
import AnalysisGroup from '@/views/MasterData/AnalysisGroup.vue';
import AnalysisGroupCategory from '@/views/MasterData/AnalysisGroupCategory.vue';
import ReportComment from '@/views/MasterData/ReportComment.vue';
import SampleBasisOfValidation from '@/views/MasterData/SampleBasisOfValidation.vue';
import PredefinedReportAttachments from '@/views/MasterData/PredefinedReportAttachments.vue';
import Departments from '@/views/MasterData/Departments.vue';
import IDepartment from '@/models/IDepartment'; //NOSONAR
import IDimension from '@/models/IDimension'; //NOSONAR
import IAnalysisProcedure from '@/models/IAnalysisProcedure'; //NOSONAR
import IPagedResult from '@/models/IPagedResult'; //NOSONAR
import IResultType from '@/models/IResultType'; //NOSONAR
import UncertaintyOfMeasurement from '@/models/UncertaintyOfMeasurementType';
import DocumentType from '@/models/DocumentType';

export default mixins(SharedDataMixin, ValidatorMixin).extend({
  components: {
    MasterDataGeneric,
    AnalysisGroup,
    AnalysisGroupCategory,
    ReportComment,
    SampleBasisOfValidation,
    PredefinedReportAttachments,
    Departments
  },
  props: {
    entityId: String,
    itemId: String
  },
  data: function () {
    return {
      entities: [
        {
          id: 'analysisgroupcategory',
          name: 'Analysegruppenkategorien',
          apiUrl: '/api/analysisgroupcategory'
        },
        {
          id: 'analysisgroup',
          name: 'Analysegruppen',
          apiUrl: '/api/analysisgroup'
        },
        {
          id: 'analysisparameter',
          name: 'Analyseparameter',
          apiUrl: '/api/analysisparameterdefinition',
          smartTableConfig: new Config(
            (row) => row.code,
            [
              new Column({
                title: 'Code',
                fieldName: 'code',
                fieldType: 'string',
                editable: () => false,
                validator: (value: string) => {
                  return {
                    isValid: !!value && value.indexOf(' ') < 0 && value.indexOf('/') < 0,
                    errorMessages: ['Code darf kein Leerzeichen oder Slash enthalten.']
                  };
                }
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Dimension',
                fieldName: 'dimensionCode',
                fieldType: 'dropdown',
                editable: () => true
              }),
              new Column({
                title: 'Tarif',
                fieldName: 'priceInChf',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue,
                colStyling: 'text-right'
              }),
              new Column({
                title: 'Tarif Null',
                fieldName: 'zeroPrice',
                fieldType: 'boolean',
                editable: () => true
              }),
              new Column({
                title: 'Abteilung',
                fieldName: 'departmentCode',
                fieldType: 'dropdown',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Berech.pos.',
                fieldName: 'calculationOrder',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Rapportpos.',
                fieldName: 'rapportPosition',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Drucken',
                fieldName: 'showInPrint',
                fieldType: 'boolean',
                editable: () => true
              }),
              new Column({
                title: 'Resultattyp',
                fieldName: 'resultTypeCode',
                fieldType: 'dropdown',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Präzision',
                fieldName: 'precision',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue,
                defaultValue: 9
              }),
              new Column({
                title: 'Interne Methode',
                fieldName: 'internalMethod',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Analyseverfahren',
                fieldName: 'analysisProcedureCode',
                fieldType: 'dropdown',
                editable: () => true
              }),
              new Column({
                title: 'Norm',
                fieldName: 'norm',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Formel',
                fieldName: 'formula',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Unterbeauftragslabor',
                fieldName: 'crmSubContractorId',
                fieldType: 'numeric',
                editable: () => true
              }),
              new Column({
                title: 'Art der Messunsicherheit',
                fieldName: 'uncertaintyOfMeasurementType',
                fieldType: 'dropdown',
                editable: () => true,
                dropdownOptions: [
                  { id: UncertaintyOfMeasurement.Percentage, title: 'Prozentual' },
                  { id: UncertaintyOfMeasurement.Absolute, title: 'Absolut' }
                ]
              }),
              new Column({
                title: 'Messunsicherheit',
                fieldName: 'uncertaintyOfMeasurement',
                fieldType: 'numeric',
                editable: () => true
              }),
              new Column({
                title: 'Nachweisgrenze',
                fieldName: 'limitOfDetection',
                fieldType: 'string',
                editable: () => true,
                validator: this.isNumeric
              }),
              new Column({
                title: 'Bestimmungsgrenze',
                fieldName: 'limitOfDetermination',
                fieldType: 'string',
                editable: () => true,
                validator: this.isNumeric
              }),
              new Column({
                title: 'Akkreditiert',
                fieldName: 'accredited',
                fieldType: 'boolean',
                editable: () => true
              }),
              new Column({
                title: 'GMP',
                fieldName: 'goodManufacturingPractice',
                fieldType: 'boolean',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('code')]
        },
        {
          id: 'orderdescriptor',
          name: 'Auftragsbezeichnungen',
          apiUrl: '/api/orderdescriptor',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Abteilung',
                fieldName: 'departmentCode',
                fieldType: 'dropdown',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'dimension',
          name: 'Dimensionen',
          apiUrl: '/api/dimension',
          smartTableConfig: new Config(
            (row) => row.code,
            [
              new Column({
                title: 'Code',
                fieldName: 'code',
                fieldType: 'string',
                editable: () => false,
                validator: (value: string) => {
                  return {
                    isValid: !!value && value.indexOf(' ') < 0,
                    errorMessages: ['Code darf kein Leerzeichen enthalten']
                  };
                }
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('code')]
        },
        {
          id: 'abbreviation',
          name: 'Abkürzungen',
          apiUrl: '/api/abbreviation',
          smartTableConfig: new Config(
            (row) => row.code,
            [
              new Column({
                title: 'Code',
                fieldName: 'code',
                fieldType: 'string',
                editable: () => false,
                validator: (value: string) => {
                  return {
                    isValid: !!value && value.indexOf(' ') < 0,
                    errorMessages: ['Code darf kein Leerzeichen enthalten']
                  };
                }
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('code')]
        },
        {
          id: 'material',
          name: 'Materialien',
          apiUrl: '/api/material',
          smartTableConfig: new Config(
            (row) => row.code,
            [
              new Column({
                title: 'Code',
                fieldName: 'code',
                fieldType: 'string',
                editable: () => false,
                validator: hasValue
              }),
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('code')]
        },
        {
          id: 'analysisprocedure',
          name: 'Analyseverfahren',
          apiUrl: '/api/analysisprocedure',
          smartTableConfig: new Config(
            (row) => row.code,
            [
              new Column({
                title: 'Code',
                fieldName: 'code',
                fieldType: 'string',
                editable: () => false,
                validator: hasValue
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('code')]
        },
        {
          id: 'reportcomment',
          name: 'Berichtskommentare',
          apiUrl: '/api/reportcomment'
        },
        {
          id: 'predefinedreportattachment',
          name: 'Berichtsanhänge',
          apiUrl: '/api/automaticreportsendingpredefinedreportattachment'
        },
        {
          id: 'samplebasisofvalidation',
          name: 'Grundlagen für Bewertung',
          apiUrl: '/api/samplebasisofvalidation'
        },
        {
          id: 'departments',
          name: 'Abteilungen',
          apiUrl: '/api/department'
        },
        {
          id: 'sampletaker',
          name: 'Probenahme',
          apiUrl: '/api/sampletaker',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'samplingpoint',
          name: 'Messstelle',
          apiUrl: '/api/samplingpoint',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Referenz',
                fieldName: 'reference',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Reihenfolge',
                fieldName: 'order',
                fieldType: 'numeric',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'employeeFunction',
          name: 'Mitarbeiterfunktionen',
          apiUrl: '/api/employeefunction',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'E-Mailadresse',
                fieldName: 'userEmail',
                fieldType: 'string',
                editable: () => true,
                validator: (x) => {
                  return {
                    isValid: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z0-9]+$/.test(x) === true,
                    errorMessages: ['Ungültiges E-Mailadressformat']
                  };
                }
              }),
              new Column({
                title: 'Vorname',
                fieldName: 'firstName',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Nachname',
                fieldName: 'lastName',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'Abteilung',
                fieldName: 'departmentCode',
                fieldType: 'dropdown',
                editable: () => true
              }),
              new Column({
                title: 'Reihenfolge',
                fieldName: 'sequenceNumber',
                fieldType: 'numeric',
                editable: () => true
              }),
              new Column({
                title: 'DE',
                fieldName: 'nameDe',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'FR',
                fieldName: 'nameFr',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'EN',
                fieldName: 'nameEn',
                fieldType: 'string',
                editable: () => true
              }),
              new Column({
                title: 'IT',
                fieldName: 'nameIt',
                fieldType: 'string',
                editable: () => true
              })
            ]
          ),
          smartTableSorting: [new Sorting('userEmail')]
        },
        {
          id: 'anion',
          name: 'Anionen',
          apiUrl: '/api/anion',
          smartTableConfig: new Config(
            (row) => row.name,
            [
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => false,
                validator: hasValue
              }),
              new Column({
                title: 'Faktor',
                fieldName: 'factor',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'cation',
          name: 'Kationen',
          apiUrl: '/api/cation',
          smartTableConfig: new Config(
            (row) => row.name,
            [
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => false,
                validator: hasValue
              }),
              new Column({
                title: 'Faktor',
                fieldName: 'factor',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'printer',
          name: 'Drucker',
          apiUrl: '/api/printer',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'Id',
                fieldName: 'id',
                fieldType: 'numeric',
                editable: () => false,
                hidden: true,
                hiddenInAddDialog: true
              }),
              new Column({
                title: 'Name',
                fieldName: 'name',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'IP-Adresse',
                fieldName: 'ipAddress',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Print Queue',
                fieldName: 'printQueue',
                fieldType: 'string',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Druckertyp',
                fieldName: 'documentType',
                fieldType: 'dropdown',
                editable: () => true,
                validator: hasValue,
                dropdownOptions: [
                  { id: DocumentType.Worksheets, title: 'Arbeitsblätter' },
                  { id: DocumentType.Labels, title: 'Etiketten' }
                ]
              })
            ]
          ),
          smartTableSorting: [new Sorting('name')]
        },
        {
          id: 'vatDefinition',
          name: 'MWST',
          apiUrl: '/api/vatDefinition',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'ID',
                fieldName: 'id',
                fieldType: 'string',
                editable: () => false,
                hidden: true,
                hiddenInAddDialog: true
              }),
              new Column({
                title: 'Steuersatz in %',
                fieldName: 'value',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Gültig ab',
                fieldName: 'effectivePer',
                fieldType: 'date',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('effectivePer', 'descending')]
        },
        {
          id: 'orderFlatRateDefinition',
          name: 'Auftragspauschale',
          apiUrl: '/api/orderFlatRateDefinition',
          smartTableConfig: new Config(
            (row) => row.id,
            [
              new Column({
                title: 'ID',
                fieldName: 'id',
                fieldType: 'string',
                editable: () => false,
                hidden: true,
                hiddenInAddDialog: true
              }),
              new Column({
                title: 'Auftragspauschalen in CHF',
                fieldName: 'value',
                fieldType: 'numeric',
                editable: () => true,
                validator: hasValue
              }),
              new Column({
                title: 'Gültig ab',
                fieldName: 'effectivePer',
                fieldType: 'date',
                editable: () => true,
                validator: hasValue
              })
            ]
          ),
          smartTableSorting: [new Sorting('effectivePer', 'descending')]
        }
      ]
    };
  },
  created: async function () {
    if (this.$departments.length > 0) {
      this.updateAllDropdownColumns(this.$departments);
    }

    await this.loadDropdowns();
  },
  watch: {
    $departments: function (value: IDepartment[]) {
      this.updateAllDropdownColumns(value);
    },
    entityId: async function () {
      await this.loadDropdowns();
    }
  },
  computed: {
    current: function (): unknown {
      return this.entities.find((entity) => entity.id === this.entityId);
    }
  },
  methods: {
    updateAllDropdownColumns: function (departments: IDepartment[]) {
      const departmentCodes = departments.map((x) => ({ id: x.code, title: x.code }));

      this.updateColumnDropdownOptions('orderdescriptor', 'departmentCode', departmentCodes);
      this.updateColumnDropdownOptions('analysisparameter', 'departmentCode', departmentCodes);
      this.updateColumnDropdownOptions(
        'employeeFunction',
        'departmentCode',
        [{ id: '', title: '' }].concat(departmentCodes)
      );
    },
    updateColumnDropdownOptions(
      entityName: string,
      fieldName: string,
      dropdownOptions: { id: string | number | null; title: string }[]
    ) {
      const entity = this.entities.find((x) => x.id === entityName);
      if (!entity) {
        return;
      }

      if (entity.smartTableConfig && entity.smartTableConfig.columns) {
        const str: string | number | null = null;
        dropdownOptions.splice(0, 0, { id: str, title: '' });
        for (const col of entity.smartTableConfig.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    loadDropdowns: async function () {
      if (this.entityId === 'analysisparameter') {
        const dimensions = (await this.loadDropdownDimensions()).withDefault([]);
        this.updateColumnDropdownOptions(
          'analysisparameter',
          'dimensionCode',
          dimensions.map((x) => ({ id: x.code, title: x.code }))
        );
      }
      if (this.entityId === 'analysisparameter') {
        const resultTypes = (await this.loadDropdownResultTypes()).withDefault([]);
        this.updateColumnDropdownOptions(
          'analysisparameter',
          'resultTypeCode',
          resultTypes.map((x) => ({ id: x.code, title: x.code }))
        );
      }
      if (this.entityId === 'analysisparameter') {
        const resultTypes = (await this.loadDropdownAnalysisProcedures()).withDefault([]);
        this.updateColumnDropdownOptions(
          'analysisparameter',
          'analysisProcedureCode',
          resultTypes.map((x) => ({ id: x.code, title: x.code }))
        );
      }
    },
    loadDropdownDimensions: async function () {
      return (await this.$labordatApi.getRd<IPagedResult<IDimension>, Error>('/api/dimension')).map(
        (data) => data.entities
      );
    },
    loadDropdownResultTypes: async function () {
      return (
        await this.$labordatApi.getRd<IPagedResult<IResultType>, Error>('/api/resulttype')
      ).map((data) => data.entities);
    },
    loadDropdownAnalysisProcedures: async function () {
      return (
        await this.$labordatApi.getRd<IPagedResult<IAnalysisProcedure>, Error>(
          '/api/analysisprocedure'
        )
      ).map((data) => data.entities);
    }
  }
});
